package com.y9vad9.site.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobwebx.markdown.markdown
import com.y9vad9.site.components.layouts.PageLayout
import com.y9vad9.site.resources.localization.LocalStrings
import kotlinx.browser.window
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Page("/blog")
@Composable
fun PostsPage() {
    PageLayout(LocalStrings.current.titleBlog) {
        Column(
            modifier = Modifier.fillMaxSize().margin(4.em),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                LocalStrings.current.titleBlog,
                Modifier.fontSize(2.25.cssRem).fontWeight(FontWeight.Bolder)
            )

            Span(Modifier.whiteSpace(WhiteSpace.PreWrap).margin(top = 2.cssRem).toAttrs()) {
                Text(LocalStrings.current.subtitleNothingHere)
            }
        }
    }
}