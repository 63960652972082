package com.y9vad9.site.resources

// language=kotlin
val ABOUT_ME_CODE = """
        val y9vad9 by developer {
            fullName = "Vadym Yaroshchuk"
            age = 0x12
    
            location {
                country = Country.GERMANY
                city = City.MUNICH
            }
            contacts {
                telegram(username = "@y9vad9")
                instagram(username = "@y9vad9")
                twitter(username = "@y9vad9")
                email(address = "dev@y9vad9.com")
            }
        }
""".trimIndent()

const val TELEGRAM_LINK = "https://t.me/vadymlog"
const val INSTAGRAM_LINK = "https://instagram.com/y9vad9"
const val LINKEDIN_LINK = "https://www.linkedin.com/in/y9vad9/"
const val GITHUB_LINK = "https://github.com/y9vad9"
const val DEV_TO_LINK = "https://dev.to/y9vad9"

const val UKRAINIAN = "uk"
const val ENGLISH = "en"