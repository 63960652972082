package com.y9vad9.site.pages.blog.uk.kotlin

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun ExtentionOrientedDesignPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("blog/uk/kotlin/ExtentionOrientedDesign.md", mapOf("title" to listOf("Extension Oriented Design"), "description" to listOf("Чому розширення власних класів – це гарний тон?"), "authors" to listOf("Вадим Ярощук"), "keywords" to listOf("[kotlin extensions, kotlin функції розширення, розширення, клас, class]"), "tags" to listOf("[kotlin, design]"), "date" to listOf("2022-11-20")))) {
        com.varabyte.kobweb.compose.foundation.layout.Column {
        org.jetbrains.compose.web.dom.H1(attrs = { id("розширення-класів") }) {
            org.jetbrains.compose.web.dom.Text("Розширення класів")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("З появою різних підходів до написання коду, люди по різному розділяли код: спочатку на функції, потім на об'єкти та зрешті на модулі та окремі проєкти. Розберім підхід, що не створює нову парадигму програмування, але створює новий підхід до написання коду: Extension oriented design (Дизайн, що орієнтований на розширення).")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("але-для-чого-нам-розширення") }) {
            org.jetbrains.compose.web.dom.Text("Але для чого нам розширення?")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Насправді причин є декілька, тож розберім їх усі.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("відсутність-доступу-до-класу") }) {
            org.jetbrains.compose.web.dom.Text("Відсутність доступу до класу")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Перша, і сама очевидна причина, чому ми потребуємо функції-розширення – це відсутність доступу до фактичного класу. Одним із видів рішення, зазвичай, було і є спадкування класу. І це прекрасно працює до моменту, коли наш клас не фінальний або наш клас це не примітив. У нас з'являється величезна проблема, яку можна вирішити за допомогою створення статичних функцій в умовних Helper'ax чи деінде.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("І тут ми плинно підходимо до того, для чого були створені extension-функції – для статичного розширення створених класів, але «після крапки», а не за допомогою аргументу функції, що допомагає в пошуках потрібної нам функції.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Тобто, замість наступного:")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""public class ListUtils {
  public static int maxOf(List<Int> list) {...}
}

public class Main {
  public static main(String[] args) {
    List<Int> list = ...;
    System.out.println(ListUtils.maxOf(list));
  }
}
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ми маємо варіант, що куди краще:")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""fun List<Int>.max(): Int {...}

fun main() {
  val list = listOf(1, 3, 9)
  println(list.max())
}
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Що має наступні переваги:")
        }
        org.jetbrains.compose.web.dom.Ul {
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("простіше шукати функції, які потрібні для конкретного типу даних (немає захаращування простору імен іншими непотрібними нам функціями)")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("не потрібно мати доступу до фактичного класу")
            }
            org.jetbrains.compose.web.dom.Li {
                org.jetbrains.compose.web.dom.Text("можна використовувати з примітивами")
            }
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Але для чого ще можна їх використовувати?")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("обхід-обмежень") }) {
            org.jetbrains.compose.web.dom.Text("Обхід обмежень")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Деякі речі, наприклад inline функції не можуть бути переназначені або ініціалізовані спадкоємцями (тобто ми не можемо використовувати їх як абстрактних членів), бо inline-функції final by definition. Тому зазвичай, наприклад для reified, використовують наступний лайфхак:")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""interface Serializer {
  fun <T> encode(kClass: KClass<T>, value: T): String
}

inline fun <reified T> Serializer.encode(value: T): String {
  return encode(T::class, value)
}
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Тобто ми розуміємо, що inline-функція за логікою не буде відрізнятись від свого не inline-відповідника. Взагалі, таке рекомендую робити не тільки для того, щоб обходити обмеження. І зараз поясню для чого.")
        }
        org.jetbrains.compose.web.dom.H3(attrs = { id("розділення-власного-коду") }) {
            org.jetbrains.compose.web.dom.Text("Розділення власного коду")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Хоча одною з переваг функцій-розширень є саме непотрібність фактичного доступу до класу, функції-розширення також використовуються для розділення вашого коду на основні та допоміжні функції, наприклад:")
        }
        org.jetbrains.compose.web.dom.Pre { org.jetbrains.compose.web.dom.Code { org.jetbrains.compose.web.dom.Text("""class Storage(...) {
  fun getStringOrNull(key: String): String {...}
}

fun Storage.getString(key: String): String = 
  getStringOrNull() ?: throw NullPointerException("${'$'}key is null")
fun Storage.getStringOrDefault(key: String, defaultVal: String) = 
  getStringOrNull() ?: defaultVal
""") } }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Тобто за аналогією з попереднім прикладом, ми виносимо функції, що не є базовою логікою (без яких функціональність класу не змінюється) окремо після нашого класу (це такий собі шаблон написання коду, що утворився в ком'юніті, як це також, наприклад, з factory-функціями, які ми виносимо перед класом).")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Можете переглянути приклади з стандартної бібліотеки: ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/JetBrains/kotlin/blob/master/libraries/stdlib/src/kotlin/util/Result.kt#L173", "kotlin.Result")
            org.jetbrains.compose.web.dom.Text(", чи з kotlinx.coroutines ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/Kotlin/kotlinx.serialization/blob/master/core/commonMain/src/kotlinx/serialization/encoding/Decoding.kt#L561", "[1]")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/Kotlin/kotlinx.serialization/blob/master/core/commonMain/src/kotlinx/serialization/encoding/Encoding.kt#L483", "[2]")
            org.jetbrains.compose.web.dom.Text(" або з ktor ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/ktorio/ktor/blob/main/ktor-io/common/src/io/ktor/utils/io/ByteReadChannel.kt#L201", "[1]")
            org.jetbrains.compose.web.dom.Text(" ")
            com.varabyte.kobweb.silk.components.navigation.Link("https://github.com/ktorio/ktor/blob/main/ktor-io/common/src/io/ktor/utils/io/core/Packet.kt#L15", "[2]")
            org.jetbrains.compose.web.dom.Text(". Погортайте власноруч ці репозиторії чи будь-які інші, й скоріш за все знайдете цей підхід.")
        }
        org.jetbrains.compose.web.dom.H2(attrs = { id("висновок") }) {
            org.jetbrains.compose.web.dom.Text("Висновок")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Ми використовуємо функції розширення з багатьох причин: технічні обмеження (такі як: недоступність фактичного класу або неможливість використання деякої функціональності типу inline-функцій), для більш ефективного пошуку потрібних нам функцій та для розділення нашого коду для більшого розуміння.")
        }
        org.jetbrains.compose.web.dom.P {
            org.jetbrains.compose.web.dom.Text("Займатись оверінжинирінгом не варто, але й ігнорувати подібні підходу також!")
        }
    }
}
}
